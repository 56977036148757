<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('customers.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    div(v-if="hasRole('admin')")
      el-card(body-style="padding: 0")
        div(slot="header" v-if="can('customers.create')")
          el-form.pt-10(:inline="true")
            el-form-item
              el-button(
                type="primary"
                :plain="true"
                @click="create"
                icon="el-icon-plus"
              ) Oluştur
        el-input(placeholder="Ara..." v-model="search" v-if="can('dealers.list')")
          i.el-input__icon.el-icon-search(slot="suffix")
        el-table(
          :data="filteredUsers"
          @row-dblclick="edit"
          :stripe="true"
          v-loading="fetching"
          size="mini"
          :height="$store.state.windowHeight-192"
          v-if="can('dealers.list')"
        )
          el-table-column(type="index" width="35px" align="right" class-name="text-muted")
          el-table-column(prop="name" label="Ad, soyad" :sortable="true")
          el-table-column(prop="phone" label="Telefon" sortable="true")
          el-table-column(prop="id" label="İşlem" align="center")
            template(v-slot="props")
              el-button-group
                el-button.micro(plain @click="edit(props.row)"): span.el-icon-edit
                el-button.micro(plain @click="remove(props.row.id)"): span.el-icon-delete.text-danger
      el-drawer(
        :visible.sync="modal"
        :title="user.name"
        size="600px"
      )
        el-form.p-20(ref="form" :model="user" label-position="top")
          el-form-item(label="Müşteri adı")
            el-input(v-model="user.name")
          el-form-item(label="Telefon numarası")
            el-input(v-model="user.phone")
        center.pt-20: el-button(@click="save" :loading="saving" icon="el-icon-check") Kaydet
</template>
<script>
import User from '@/models/User'

export default {
  name: 'customers',
  data(){
    return {
      user: { name: '' },
      users: [],
      modal: false,
      fetching: false,
      saving: false,
      search: ''
    }
  },
  async created () {
    if(this.can('customers.list')) {
      this.fetch()
    }
  },
  methods: {
    async fetch(excel) {
      try{
        this.fetching = true
        this.users = await User
          .whereIn('roles', ['customer'])
          .get()
        this.fetching = false
      } catch({ response }) {
        this.fetching = false
        this.$message.error(response.data.message)
      }
    },
    create(){
      this.user = new User({
        name: '',
        phone: '',
        active: true,
        account_transfer: 0,
        roles: ['customer']
      })
      this.modal = true
    },
    async edit(row){
      try {
        this.user = await User.find(row.id)
        this.modal = true
      } catch({ response }) {
        this.$message.error(response.data.message)
      }
    },
    async save(){
      try{
        this.saving = true
        await this.user.save()
        this.saving = false
        this.modal = false
        await this.fetch()
      } catch(e) {
        this.saving = false
      }
    },
    async remove(id){
      await this.$confirm(
        'Kayıt silinmesine onay veriniz',
        'Uyarı',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Vazgeç',
          type: 'warning',
          center: true
        }
      )
      try{
        let { data } = await window.axios.delete('/users/' + id)
        this.fetch(false)
        if(data.deleted){
          this.$message({
            type: 'success',
            message: 'Kayıt silinmiştir',
            center: true,
            closable: true
          })
        }
      } catch(e) {
        this.$message({
          type: 'error',
          message: 'Üzerine satış, rezervasyon veya ödeme tanımlanmıştır. Kayıt silinemez',
          center: true,
          closable: false
        })
      }
    },
  },
  computed: {
    saveable: function(){
      return this.user.name.length > 0
    },
    filteredUsers: function(){
      return this.users.filter(u => u.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>
